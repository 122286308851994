<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-fade-transition>
      <v-overlay
        :value="isForwarding"
        :opacity="1"
        style="z-index: 1000;"
      >
        <v-alert type="success" border="bottom">
          {{ $t('components.website.userCartVerify.forwarding') }}
          <v-progress-circular indeterminate />
        </v-alert>
      </v-overlay>
    </v-fade-transition>
    <v-card-title>{{ $t('components.website.userCartVerify.title') }}</v-card-title>
    <v-card-text>
      <user-cart-list :cart="cart" preview />
    </v-card-text>
    <v-card-text>
      <cart-delivery :cart="cart" />
      <v-alert
        v-if="deliveryAlert"
        text
        type="warning"
        border="bottom"
        class="my-1 mx-3"
        icon="mdi-alert"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ deliveryAlert }}
          </v-col>
          <v-col class="shrink">
            <v-btn text to="/faqs" target="_blank">
              {{ $t('components.website.userCart.seeDeliveryFaqs') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-divider />
      <cart-amount :cart="cart" selected-delivery />
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        :block="$vuetify.breakpoint.xs"
        @click="onGoToBank"
      >
        {{ $t('components.website.userCart.goToBank') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import UserPurchasingCart from '@peynman/press-vue-core/mixins/UserPurchasingCart'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import UserCartList from './UserCartList.vue'
  import CartAmount from './CartAmount.vue'
  import CartDelivery from './CartDelivery.vue'
  import { redirectToUrl } from '@peynman/press-vue-core/utils/helpers'
  import Constants from '../../constants'

  export default {
    name: 'UserCartValidateCard',
    components: {
      UserCartList,
      CartAmount,
      CartDelivery,
    },
    mixins: [
      Themeable,
      UserPurchasingCart,
    ],
    props: {
      gateway: [Number, String],
      message: String,
    },
    data: vm => ({
      isForwarding: false,
    }),
    computed: {
      deliveryAlert () {
        return this.cartDeliveryAgent?.hint
      },
    },
    mounted () {
      this.isForwarding = false
      this.$store.dispatch('analytics/trackGoalConversion', {
        goalId: Constants.Matomo.Goals.visited_checkout,
        revenue: this.cart.amount,
      })
    },
    methods: {
      onGoToBank () {
        const cartId = this.cart.id
        this.$store.commit('cart/resetCart')
        this.isForwarding = true
        this.$store.dispatch('analytics/trackGoalConversion', {
          goalId: Constants.Matomo.Goals.visited_bank,
          revenue: this.cart.amount,
        })
        redirectToUrl(
          this.$store.getters.getUrl('/bank-gateways/checkout') + '?jwttoken=' + this.$store.getters.jwtToken,
          {
            gatewayId: this.gateway,
            cartId,
            successRedirect: this.$store.getters.getWebsiteUrl('/me/carts'),
            failedRedirect: this.$store.getters.getWebsiteUrl('/me/cart'),
            canceledRedirect: this.$store.getters.getWebsiteUrl('/me/cart'),
          },
        )
      },
    },
  }
</script>
