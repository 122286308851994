<template>
  <website>
    <user-cart-validate-card :gateway="gateway" />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import UserCartValidateCard from '../components/UserCart/UserCartValidateCard.vue'

  export default {
    name: 'PurchasingCartVerify',
    components: {
      Website,
      UserCartValidateCard,
    },
    props: {
      gateway: [Number, String],
    },
  }
</script>
